import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import './index.css';
import { Box, Grid } from '@mui/material';
import LoginPortal from './login';
import TotalExpenses from './reports/TotalExpenses';
import YearsInProject from './reports/YearsInProject';
import { RequireToken } from './auth';
import ReportApp from './App';
import ReportCard from './ReportCard';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RequireToken>
      <ReportApp bannerTitle="Clover Reports">
        <Box m={2} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} rowSpacing={12}>
            <Grid item sm={4} xs={12}>
              <ReportCard
                reportName="Total Project Expense"
                reportDescription="The total expenses for each youth participating in a specified project."
                pathLink="/total-expenses"
              />
              <ReportCard
                reportName="Years in Project by Member"
                reportDescription="Number of years each member has participated in a specified project."
                pathLink="/years-in-project"
              />
            </Grid>
          </Grid>
        </Box>
      </ReportApp>
    </RequireToken>},
  {
    path: "total-expenses", element: <RequireToken>
      <ReportApp bannerTitle="Total Project Expenses">
        <TotalExpenses />
      </ReportApp>
    </RequireToken>},
  {
    path: "years-in-project", element: <RequireToken>
      <ReportApp bannerTitle="Years in Project">
        <YearsInProject />
      </ReportApp>
    </RequireToken>},
  {
    path: "login", element: <LoginPortal />},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
