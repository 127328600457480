
import { api_client } from '../auth_api';
import { fetchToken } from '../auth';
  
export function getCountyNames({
  onLoad,
  onLoadDefault = null,
}) {
  // Get Default County
  api_client.get("fields/counties/default", {
      withCredentials: true,
      headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${fetchToken()}`,
      },
  }).then(res => res.data).then(jsonData => {
      // Set the Default County Name
      if (!!onLoadDefault) {
      onLoadDefault(jsonData);
      }
  })
  .catch((error) => {
      if( error.response ){
      console.log(error.response.data); // => the response payload
      }
  });

  // List all Counties
  api_client.get("fields/counties", {
      withCredentials: true,
      headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${fetchToken()}`,
      },
  }).then(res => res.data).then(jsonData => {
      // Set the County Names
      onLoad(jsonData);
  })
  .catch((error) => {
      if( error.response ){
      console.log(error.response.data); // => the response payload
      }
  });
}
    
export function getProjectNames({
  onLoad,
}) {
  // List all Projects
  api_client.get("fields/projects", {
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${fetchToken()}`,
    },
  }).then(res => res.data).then(jsonData => {
    // Set the Project Names
    onLoad(jsonData);
  })
  .catch((error) => {
    if( error.response ){
      console.log(error.response.data); // => the response payload
    }
  });
}

export function getClubNames({
  onLoad,
  county=null
}) {
  // List all Clubs
  let uri = "fields/county-clubs"
  if (!!county) {
    // counties.filter((c) => c.name === county)[0].id
    uri += `?county_id=${county}`
  }
  api_client.get(uri, {
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${fetchToken()}`,
    },
  }).then(res => res.data).then(jsonData => {
    // Set the Project Names
    onLoad(jsonData);
  })
  .catch((error) => {
    if( error.response ){
      console.log(error.response.data); // => the response payload
    }
  });
}