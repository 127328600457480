import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function ReportCard({
  reportName, // Name of Report as it should appear
  reportDescription, // Brief Summary of Report
  pathLink, // Link of Path to Direct
}) {
  const [hovered, setHovered] = useState(false);

  const navigate = () => {
    // Absolute Path
    if (pathLink.startsWith('/')) {
      window.location.href = pathLink;
      return;
    }
    // Relative Path
    const host_url = new URL(window.location.href);
    const new_url = new URL(host_url.pathname + "/" + pathLink, host_url);
    window.location.href = new_url.href;
  }

  return (
    <Card
      onClick={navigate}
      onMouseOut={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
      style={{
        transform: `${hovered ? 'scale(1.2,1.2)' : 'scale(1,1)'}`,
        zIndex: `${hovered ? '999' : '1'}`
      }}
    >
      <CardActionArea>
        <CardContent color="primary.main">
          <br/>
          <Typography gutterBottom variant="h5" component="div">
            {reportName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {reportDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
